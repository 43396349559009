import React from "react";

function Tribe() {
  return (
    <span style={{ fontWeight: 700 }}>
      <span style={{ color: "#E19F4C" }}>T</span>
      <span style={{ color: "#6CADD3" }}>R</span>
      <span style={{ color: "#FFFFFF" }}>I</span>
      <span style={{ color: "#CE566C" }}>B</span>
      <span style={{ color: "#8FBF7B" }}>E</span>
    </span>
  );
}

export default Tribe;
