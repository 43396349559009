import React, { useContext } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import Slider from "react-slick";
import { DataContext } from "DataContainer";
function Sponsor({ sponsors }) {
  let pageHeader = React.createRef();
  // const [overlay, setOverlay] = React.useState(false);
  const settings = {
    // dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 2,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          row: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          row: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { pastSponsors: allSponsors } = useContext(DataContext);

  return (
    <div
      style={{
        backgroundColor: "transparent",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: 5,
      }}
      className="section section-with-space"
      data-parallax={true}
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <h2 className="text-700 text-center text-white mt-0 mb-4">
              PAST SPONSORS AND PARTNERS
            </h2>
          </Col>
        </Row>
        <Row style={{}} className="pt-4 pb-2 bg-white">
          <Col lg={12} xs={12}>
            <Slider {...settings}>
              {allSponsors.map((data, i) => (
                <Card key={i} style={{ borderRadius: 0, boxShadow: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      aspectRatio: "2/1",
                      justifyContent: "center",
                      background: "#fff",
                      padding: "1em",
                      backgroundColor: "#fff",
                    }}
                  >
                    <img
                      src={data.image}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100px",
                        // height: "150px",
                        alignSelf: "center",
                      }}
                      alt={data.name}
                    />
                  </div>
                </Card>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Sponsor;
