import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
function Testimonials() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#4f2a76",
          // backgroundImage: `url(${require("assets/images/asset7.jpg")})`,
          backgroundSize: "cover",
          borderTop: "15px solid #4f2a76",
          borderBottom: "15px solid #4f2a76",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row></Row>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="text-center">
                <h1 className="text-300 text-center text-white" text-center>
                  Testimonials
                </h1>
              </div>
            </Col>

            <Col lg={12} xs={10} className="text-center">
              <Slider {...settings}>
                {content.map((c) => (
                  <>
                    <h4
                      className="text-white text-400 px-3"
                      dangerouslySetInnerHTML={{ __html: c.t }}
                    />
                    <br />
                    <h4
                      className="text-white text-600"
                      style={{ fontWeight: 700 }}
                      dangerouslySetInnerHTML={{ __html: c.b }}
                    />
                  </>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    t: `Joining the power-packed panel at TRIBE – The CMO Connect on 27 April was a total thrill. Big shoutout
to the organizers- Khaleej Times and Mitsu Ruparel for putting together this incredible event and
knowledge-exchange platform. Had a great time being part of the conversations on the strategic
importance of marketing, the changing gameplay and the power of storytelling with truly top-level
marketers of renowned brands Siladitya Bandyopadhyay Shafika HOUCINE Neeraj Chaturvedi and
moderated by Dr. Tabani N. Big thanks to the crowd too for being enthusiastic, curious and engaged
throughout the event`,
    b: "Katib Belkhodja, Customer Centricity Director - Al-Futtaim Automotive",
  },

  {
    t: `Thank you Candice D&#39;Cruz, Sadia Akhter, Ravi Rao and Khaleej Times for a really great event and a fun
and stimulating panel discussion. I thoroughly enjoyed the experience and walk away more humbled by
the interactions. Mitsu Ruparel this post wouldn’t be complete without a special mention to you for
pulling this event and all of us from the fraternity together. You are an absolute rockstar! James Taylor,
Aoun Raza and Neeraj Chaturvedi amongst others whom I enjoyed listening to… thank you for the
anecdotes`,
    b: "Siddarth Sivaprakash, Head of Marketing & Brand - Home Centre",
  },

  {
    t: `Data Protection is often seen as a challenge for marketers however if you do not look at it through the
appropriate lens you will erode the trust in your customers and future customers. Complying with data
protection laws will ensure you avoid legal and regulatory issues as well as protecting your brand and its
reputation. The Khaleej Times event was key to raising this awareness as well as bringing other keynote
speakers and participants to key marketing topics. A huge thankyou to all the Khaleej Times team
involved in such a successful event. It was wonderful to be part of the TRIBE!`,
    b: "Richard Chudzynski, Middle East Data Protection and Privacy Leader – PwC",
  },

  {
    t: `The event was a game-changer for marketing leaders, bringing together the brightest minds in the
industry to not only identify upcoming trends and challenges, but to also collaboratively devise
innovative solutions for driving growth in today’s fast-paced business world. Thank you to the KT team
for skillfully orchestrating a dynamic and engaging forum that fostered thought-provoking
conversations, networking opportunities, and valuable insights from top marketing experts, leaving
attendees inspired and energized to tackle the ever-evolving landscape of marketing!`,
    b: "Shafika Houcine, Group Director, Digital Channels - e&;",
  },

  {
    t: `It was an honor to attend TRIBE – The CMO connect hosted by Khaleej Times at address Skyview Dubai. I
am grateful for the opportunity to meet, interact and discuss the below subjects with the top talented
marketers in the region. Power of storytelling: How to drive deeper engagements while being aligned
with brand’s purpose, Influencer Marketing: Pros and Cons, Data Privacy: Marketing in an era of Privacy,
Regulations and Customer trust, Hyper personalization: Can we become a customer champion with
apps, ML algos, Personalized recommendations, Immersive experiences and AIs?, Why are marketers 
still struggling with delivering a seamless customer experience?, Marketing ethics – Where does
Marketing draw a line? and Through the marketing lens - Web 3.0, Metaverse, NFTs and other new
releases and its impact on marketing. Thank you Khaleej Times Events for the invitation. It was really a
well-organized event. Well done team.`,
    b: "Amr Gawish, Regional Marketing Manager - Baskin Robbins",
  },

  {
    t: `Thank you to Khaleej Times for a great event, thoroughly enjoyed the sessions and being up on stage
with my fellow #web3 peep Leila Hurstel & great moderator Antonio Boulos`,
    b: "Andrew Thomas, Managing Director – Nexa",
  },

  {
    t: "Engaging and insightful. That’s what the sessions at Tribe was - kudos, all!",
    b: "Nandita Saggu, Partner – YAAP",
  },

  {
    t: `Thanks, Khaleej Times Events for such exciting and informative event the #TRIBE 2023 - The CMO
Connect with remarkable fellow marketers from top organizations across the country and region. -
Mohamed Farouk Hamid, Assistant Manager CRM & Digital - Galadari Automotive Group.
It was a pleasure to meet senior marketers, influencers, and thought-leaders of the UAE who gathered
at the Khaleej Times event &quot;TRIBE - The CMO CONNECT&quot; to explore the potential of emerging
technologies like the metaverse, NFTs, and Web 3.0 for marketing.`,
    b: "Leila Hurstel, Chief Metaverse Officer – VerseEstate",
  },

  {
    t: `Attending the event organized by KT was truly a fantastic experience, providing invaluable insights and
discussions around the latest industry trends and experiences for marketers. It was a great platform to
connect with other professionals and share knowledge. Thank you, KT, for creating such a wonderful
opportunity!`,
    b: "Meenu Singh, Head of Marketing – Dubai Outlet Mall",
  },

  {
    t: `I recently attended the Khaleej times CMO Tribe event and wanted to extend my sincere appreciation
for this incredible industry event. The quality of the speakers, sessions, and networking opportunities
were truly outstanding. A big shout out to the Khaleej Times event team for your hard work and
dedication in putting together such a fantastic event.`,
    b: "Ankesh Agarwal, Group CX Strategy - MAJID AL FUTTAIM HOLDING LLC",
  },
];

export default Testimonials;
