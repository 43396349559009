import React from "react";

function ProfilePageHeader({ title, bgColor, noPadding }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "#4f2a76",
          // marginTop: !noPadding && "6rem",
        }}
        className="section py-5"
        data-parallax={true}
      >
        {title ? <h1 className="text-300 text-uppercase text-center text-white">{title}</h1> : ""}
      </div>
    </>
  );
}

export default ProfilePageHeader;
