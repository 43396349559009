import React from "react";
import { Container, Row, Col, Button, Card, CardImg } from "reactstrap";

import "react-circular-progressbar/dist/styles.css";
import Tribe from "./Tribe";
import SpeakerCard from "./SpeakerCard";
// import SpeakerCardOld from "./SpeakerCardOld.js";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  // const mdSize =
  //   document.body.clientWidth >= 1324 || document.documentElement.clientWidth >= 1324
  //     ? true
  //     : false;

  return (
    <>
      <section id="about">
        <div
          style={{
            background: "#4f2a76",
            backgroundImage: size && `url(${require("assets/images/Asset2.png")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="section py-0"
          data-parallax={true}
          ref={pageHeader}
        >
          <Container>
            <Row className="justify-content-center">
              <Col
                md={10}
                xs={12}
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              >
                <div
                  className="text-dark"
                  style={{ padding: size ? "0rem 1rem 3rem 1rem" : "3rem 1rem 4rem 1rem" }}
                >
                  <h1 className="text-300 text-center text-white" text-center>
                    A Glimpse into the Future
                  </h1>
                  <h4 className="text-400 text-white text-center">
                    In a world where innovation is the lifeblood of success, chief marketing
                    officers (CMOs) stand at the crossroads of trends, technologies, and
                    transformative strategies. Striking the right balance between hype and
                    investment is an art, and at TRIBE 2024, we bring you the science behind it. Our
                    summit is not just a meeting; it's a convergence of the minds shaping the
                    marketing revolution.
                    <br />
                    <br />
                    Embark on a journey into the heart of marketing evolution at the second edition
                    of TRIBE 2024 - The CMO Connect. This year, we're diving deep into the theme of
                    "Harmonizing Innovations: Navigating Next-Gen Marketing," where we explore the
                    ever-evolving landscape of marketing, seamlessly blending tried-and-tested
                    strategies with cutting-edge technologies.
                    <br />
                    <br />
                    <b>Harmonizing Innovations</b>
                    <br />
                    <br />
                    CMOs of the future are not just marketers; they are architects of
                    transformational change. Through the utilization of Big Data, Customer
                    Analytics, and emerging technologies like AI, IoT, and VR, they are rewriting
                    the rules and setting new standards for customer engagement.
                  </h4>
                  <br />
                </div>
              </Col>
            </Row>
          </Container>
          <Container className=" mb-3">
            <Row className="justify-content-center ">
              <Col lg={12}>
                <h1
                  className="text-300 m-0 my-3 pt-2 text-left"
                  style={{
                    color: "#fcd456",
                  }}
                >
                  Welcome To
                </h1>
              </Col>
              <Col lg={6} md={6} xs={12} className="align-self-top py-0 border-right">
                <img src={require("assets/images/Logo.png")} width="90%" alt="main logo" />
                <h3 className={`text-400 text-white pl-2`}>
                  <Tribe /> is all about bringing together the most forward-thinking marketers in
                  the Middle East to share their tried, tested, and proven strategies with you. The
                  CMOs and Marketing Heads of the future will get closer to their customers than
                  ever before, driving transformational change across their organizations through
                  the use of Big Data, Customer Analytics, and emerging technologies such as AI,
                  IOT, and VR
                </h3>
              </Col>
              <Col lg={6} md={6} xs={12} className="align-self-top ">
                <div class="embed-responsive embed-responsive-4by3">
                  <iframe
                    class="embed-responsive-item"
                    src="https://www.youtube.com/embed/0qezFwRoO94"
                    allowfullscreen
                    title="tribe"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-5">
            <Row className="justify-content-center ">
              <Col lg={6} md={6} xs={12} className="align-self-top ">
                <h3 className={`text-400 text-white pl-2 pb-3`}>
                  At <Tribe />, you'll get to network with some of the biggest names in Marketing,
                  Communications, Digital, and Engagement from around the world. You'll hear about
                  best practice case studies and discuss the changes and opportunities facing our
                  industry. It's time to sense check your approach with like-minded industry
                  experts, share insight on business-critical challenges and disruptive technology,
                  and drive our industry forward together.
                </h3>
              </Col>

              <Col lg={6} md={6} xs={12} className="align-self-top py-0">
                <img src={require("assets/images/WP 2.png")} width="90%" alt="main logo" />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="justify-content-center">
              <Col
                md={10}
                xs={12}
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              >
                <div
                  className="text-dark"
                  style={{ padding: size ? "0rem 1rem 3rem 1rem" : "3rem 1rem 4rem 1rem" }}
                >
                  <h1 className="text-300 text-center text-white" text-center>
                    So what are you waiting for?
                  </h1>
                  <h3 className="text-400 text-white text-center">
                    <a href="/register" className="text-white">
                      Join us at <Tribe />
                    </a>{" "}
                    - The CMO Connect and let's take marketing to the next level!
                  </h3>

                  <br />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <div
        style={{ background: "#4f2a76" }}
        className="section pt-0"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container className="pb-5">
          <Row className="justify-content-center">
            <Col lg={12}></Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={12} className="text-dark text-center">
              <h1
                className="text-700 m-0 my-5"
                style={{
                  color: "#fff",
                }}
              >
                Why you cannot miss this event?
              </h1>
            </Col>
            <Col lg={12}>
              <Row className="mt-3 justify-content-center">
                {content1.map((c, i) => (
                  <Col lg={3} xs={12} className="pb-2 " key={i}>
                    <Card style={{ background: "transparent", boxShadow: "none" }}>
                      <CardImg
                        src={require(`assets/images/${c.image}`)}
                        alt="image"
                        style={{ background: "transparent" }}
                      />
                      <h4 className="text-600 mt-2 mb-2 text-center text-white">{c.title}</h4>
                      {/* <p className="text-400 mt-2 mb-4  text-white">
                        <ul>
                          {c.points.map((c) => (
                            <li key={c}>{c}</li>
                          ))}
                        </ul>
                      </p> */}
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <section id="sponsor">
        <div
          style={{
            background: "#4f2a76",
            backgroundImage: size && `url(${require("assets/images/Listenup.png")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className={size ? "page-header" : "section py-0"}
          data-parallax={true}
          ref={pageHeader}
        >
          <Container>
            <Row className="justify-content-end">
              <Col
                md={5}
                xs={12}
                className="align-self-end"
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              >
                {!size && (
                  <img src={require("assets/images/Listen up.png")} width="100%" alt="main logo" />
                )}
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col
                md={5}
                xs={12}
                className="align-self-end"
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              ></Col>
              <Col
                md={7}
                xs={12}
                className="align-self-end"
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              >
                <div className="text-dark">
                  <h3 className="text-400 text-white">
                    Are you ready to make some serious connections with CMO decision-makers? Then
                    listen up, because we've got an opportunity for you!
                    <br />
                    <br />
                    Become a partner at TRIBE and you'll have the chance to meet face-to-face with
                    decision-makers in attendance through a series of one-on-one executive exchange
                    meetings.
                    <br />
                    <br />
                    And that's not all. You'll also get to lead interactive main stage and think
                    tank sessions where you can share your expertise as a thought leader on our
                    agenda. Show off your marketing chops and impress the audience with your
                    knowledge.
                    <br />
                    <br />
                    So don't miss out on this chance to partner up with us at TRIBE. Get in touch
                    now to find out more and take your marketing game to the next level!
                  </h3>
                  <br />
                  <Button
                    href="/register"
                    className="navbar-item-custom text-400 text-dark rounded-0 "
                    style={{
                      background: "#f8b411",
                      border: "2px solid #000",
                    }}
                    size="lg"
                  >
                    <span
                      style={{
                        color: "#000",
                        textTransform: "none",
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                      className="text-600"
                    >
                      REGISTER NOW <i class="fa fa-arrow-right" />
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section id="speakers">
        {/* <Container className="pt-5">
          <Row className="justify-content-center">
            <Col
              md={10}
              xs={12}
              style={{
                // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                backgroundSize: "cover",
                backgroundRepeat: "none",
              }}
            >
              <div
                className="text-dark"
                style={{ padding: size ? "0rem 1rem 3rem 1rem" : "3rem 1rem 4rem 1rem" }}
              >
                <h1 className="text-300 text-center text-white" text-center>
                  Speakers List !
                </h1>
                <h3 className="text-400 text-white text-center">
                  We are scouting the universe for the best-in-the-class speakers! Shall be updating
                  our stars soon..
                </h3>

                <br />
              </div>
            </Col>
          </Row>
        </Container> */}
        <SpeakerCard />
        {/* <SpeakerCardOld /> */}
      </section>
      <section id="attend">
        <div
          style={{
            background: "#4f2a76",
            backgroundImage: size && `url(${require("assets/images/BG2.png")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className={size ? "page-header" : "section py-0"}
          data-parallax={true}
          ref={pageHeader}
        >
          <Container>
            <Row className="justify-content-center">
              <Col
                md={7}
                xs={12}
                className="align-self-end"
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              >
                <div className="">
                  <h1 className="text-300 text-center text-white" text-center>
                    WHO ATTENDS ?
                  </h1>
                  <h3 className="text-600 text-white">
                    <ul>
                      <li>Chief Marketing Officers</li>
                      <br />
                      <li>VP’s/ Directors/ Head of Marketing</li>
                      <br />
                      <li>VP’s/ Directors/ Head of Digital Marketing</li>
                      <br />
                      <li>VP’s/ Directors/ Head of Channel Marketing</li>
                      <br />
                      <li>VP’s/ Directors/ Head of Sales</li>
                      <br />
                      <li>VP’s/ Directors/ Head of Communications</li>
                      <br />
                      <li>VP’s/ Directors/ Head of PR</li>
                      <br />
                      <li>Omnichannel / CRM / CX – Analytics / Insights / Market Research</li>
                      <br />
                      <li>Data Scientists</li>
                      <br />
                    </ul>
                  </h3>

                  <br />
                  <Button
                    href="/register"
                    className="navbar-item-custom text-400 text-dark rounded-0 "
                    style={{
                      background: "#f8b411",
                      border: "2px solid #000",
                    }}
                    size="lg"
                  >
                    <span
                      style={{
                        color: "#000",
                        textTransform: "none",
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                      className="text-600"
                    >
                      REGISTER NOW <i class="fa fa-arrow-right" />
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}

export default TextGrid;

const content1 = [
  {
    title: "Capitalizing on a Multi-Billion Dollar Opportunity: -",
    points: [
      "Unlock the secrets to maximizing and gaining measurable results from your data and insights. ",
      "Seize the potential of a lucrative multi-billion-dollar market.",
    ],
    image: "WP 8.png",
  },
  {
    title: "Networking at its Pinnacle: -",
    points: [
      "Rub shoulders with the crème de la crème of marketers from diverse industries. ",
      "Forge connections that transcend industries and truly matter for your professional growth.",
    ],
    image: "WP 5.png",
  },

  {
    title: "Insights from the Trailblazers",
    points: [
      "Hear insights from the best marketers, agencies, corporations, and influencers. ",
      "Engage in discussions about the ever-evolving role of marketers in today's dynamic landscape.",
    ],
    image: "WP 4.png",
  },
  {
    title: "Revolutionizing Customer Connections",
    points: [
      "Invigorate your customer connections through personalization. ",
      "Learn strategies to elevate customer engagement and loyalty in an era where personalization is key.",
    ],
    image: "WP 7.png",
  },
  // {
  //   title: "Unlocking Creativity with Martech Solutions",
  //   points: [
  //     "Discover innovative and creative martech solutions. ",
  //     "Learn how these solutions guide marketers in creating personalized, measurable, and brand-oriented strategies.",
  //   ],
  //   image: "WP 5.png",
  // },
  // {
  //   title: "Mastering B2B Influence",
  //   points: [
  //     "Identify platforms and tools that wield influence over B2B buying decisions. ",
  //     "Elevate your strategies for engagement and growth in the B2B landscape.",
  //   ],
  //   image: "WP 5.png",
  // },
  // {
  //   title: "Harnessing the Power of AI and Chatbots",
  //   points: [
  //     "Understand the true value of AI, chatbots, and other innovative technologies in your marketing and engagement journeys.",
  //     "Position yourself at the forefront of technological advancements driving marketing effectiveness.",
  //   ],
  //   image: "WP 5.png",
  // },

  // {
  //   title: "Pioneering Martech and Innovation",
  //   points: [
  //     "Propel yourself ahead of the industry curve. ",
  //     "Align with pioneers in martech and new-age marketing, establishing yourself as an innovative force in the field.",
  //   ],
  //   image: "WP 5.png",
  // },
];
